import config from './config'
import LineBuildingIcon from 'components/Luxkit/Icons/line/LineBuildingIcon'
import LineClockDollarIcon from 'components/Luxkit/Icons/line/LineClockDollarIcon'
import LineRefundIcon from 'components/Luxkit/Icons/line/LineRefundIcon'
import LineSunsetIcon from 'components/Luxkit/Icons/line/LineSunsetIcon'
import { OFFER_TYPE_TOUR, OFFER_TYPE_TOUR_V2 } from './offer'
import { TOUR_FLEXIBLE_DURATION_RANGE } from 'constants/search'
import { DurationOption } from 'components/Search/SearchForm/SearchDateInput/FlexibleDatePicker/DurationPicker'
import { OfferContextMap } from 'analytics/snowplow/context'

export const TOUR_CHILD_MAX_AGE = 15
export const TOUR_V2_OFFER_DUE_DATE_DAY_THRESHOLD = 105

export const DIRECT_TOUR = 'direct_tour'
export const PARTNER_TOUR = 'partner_tour'
export const CONNECTION_TOUR = 'connection_tour'

export const ULTRA_LUX_TOUR = 'ultra_lux_tour'

export const TOUR_V2_OFFER_LIST_TYPES: Array<Tours.V2ParentOfferType> = [OFFER_TYPE_TOUR_V2]
export const TOUR_V1_OFFER_LIST_TYPES: Array<App.OfferType> = [OFFER_TYPE_TOUR]
export const TOUR_V1_OFFER_LIST_FILTER_TYPES: Array<App.OfferType> = [OFFER_TYPE_TOUR]
export const TOURS_OFFER_LIST_EXCLUDED_PARTNERSHIP_CODES = new Set([
  'qantas',
  'krisFlyer',
  'velocity',
])

export const DISCONTINUED_TOUR_IDS = [
  '83b9b546-9bc4-4b62-ba27-d6fa5075a1e4',
  '5c14ddb1-ad88-4dc1-9727-fa992a48922e',
  '8a49e66c-7fe3-4d1c-98d1-9816fe5886e2',
  'bb86692d-62ac-4770-b25f-c6cf682c7f9a',
  '94e76f60-11ad-4844-bf4f-8b34810d0b09',
  'b13e3b83-54cf-48e6-b3a0-e5dc222e0979',
  '3b6bb095-efd7-483d-bc69-4a689dc25614',
]

export const TOUR_V2_BOOK_WITH_CONFIDENCE_ITEMS: Array<Tours.V2OfferBookWithConfidenceItem> = [
  {
    title: 'Book everything in one place',
    description: 'From hotels to flights, experiences and more – we’ve got your trip covered from start to finish.',
    Icon: LineSunsetIcon,
  },
  {
    title: 'Secure dates with a deposit',
    description: `Reserve your space now with a deposit, pay the rest ${TOUR_V2_OFFER_DUE_DATE_DAY_THRESHOLD} days before departure`,
    Icon: LineClockDollarIcon,
  },
  {
    title: '7-day change of mind refund',
    description: 'Get a full refund on your booking (excludes cruises, flights, travel protection and the service fee paid on deposit bookings)',
    Icon: LineRefundIcon,
  },
  {
    title: 'Handpicked partners',
    description: 'We work with the world’s most loved brands in travel',
    Icon: LineBuildingIcon,
  },
]

export const TOUR_ORDER_TRAVELLER_DETAILS_TTL: number = 300 // in seconds

export enum TourV2ContentSections {
  GALLERY = 'gallery',
  OVERVIEW = 'overview',
  ITINERARY = 'itinerary',
  VARIATIONS = 'variations',
  INCLUSIONS = 'inclusions',
  DEPARTURES = 'dates-and-pricing',
  FINE_PRINT = 'things-to-know',
  FAQ = 'frequently-asked-questions',
  YOU_MAY_ALSO_LIKE = 'you-may-also-like',
  REVIEWS = 'reviews',
}

export const TOUR_V2_CONTENT_SECTION_LABELS: Record<Exclude<TourV2ContentSections, TourV2ContentSections.GALLERY>, string> = {
  [TourV2ContentSections.OVERVIEW]: 'Overview',
  [TourV2ContentSections.ITINERARY]: 'Itinerary',
  [TourV2ContentSections.VARIATIONS]: 'Variations',
  [TourV2ContentSections.INCLUSIONS]: 'Highlights & inclusions',
  [TourV2ContentSections.DEPARTURES]: 'Dates & pricing',
  [TourV2ContentSections.FINE_PRINT]: 'Things to know',
  [TourV2ContentSections.FAQ]: 'Frequently Asked Questions',
  [TourV2ContentSections.YOU_MAY_ALSO_LIKE]: 'You may also like',
  [TourV2ContentSections.REVIEWS]: 'Reviews',
}

export const TOUR_V2_STICKY_HEADER_ENABLED_SECTIONS = new Set<TourV2ContentSections>([
  TourV2ContentSections.OVERVIEW,
  TourV2ContentSections.ITINERARY,
  TourV2ContentSections.VARIATIONS,
  TourV2ContentSections.INCLUSIONS,
  TourV2ContentSections.DEPARTURES,
  TourV2ContentSections.FINE_PRINT,
  TourV2ContentSections.FAQ,
  TourV2ContentSections.REVIEWS,
])

export const TOUR_V2_TARGET_OCCUPANT_TYPES: Set<App.OccupantType> = new Set(['adults', 'children'])

export const TOUR_V2_DEFAULT_OCCUPANCIES: Array<App.Occupants> = [
  {
    adults: 2,
    children: 0,
    infants: 0,
    childrenAge: [],
  },
]

export const TOUR_V2_MAX_POSSIBLE_OCCUPANCIES = [{ adults: 4, children: 3, infants: 3, total: 4 }]

export const TOUR_V2_PRIVATE_MAX_POSSIBLE_OCCUPANCIES = [{ adults: 3, children: 2, infants: 2, total: 3 }]

export const LE_TOUR_V2_MAX_POSSIBLE_OCCUPANCIES = [{ adults: 2, children: 0, infants: 0, total: 2 }]

export const TOUR_V2_SEARCH_TYPES: Array<App.SearchType> = ['destination', 'tour']

export const TOUR_V2_SEARCH_HERO_IMAGE_ID = 'adkvxdgnii1kvb4s970c'

export const TOUR_V2_LOCATION_SEARCH_TITLE_LABEL = 'Where'

export const TOUR_V2_LOCATION_SEARCH_PLACEHOLDER_LABEL = 'Search destination or place'

export const TOUR_V2_LOCATION_SEARCH_ERROR_MESSAGE = config.searchFormSection?.escapeSynonym ?
  `Check your spelling, try a new destination or place for your next ${config.searchFormSection.escapeSynonym}.` :
  'Check your spelling, try a new destination or place.'

export const TOUR_V2_LOCATION_SEARCH_ERROR_TOOLTIP_MESSAGE = 'Please enter a destination or place'

export const TOUR_V2_LOCATION_SEARCH_TYPEAHEAD_TYPES: Array<App.SearchPlaceType> = [
  'city',
  'colloquial_area',
  'continent',
  'country',
  'high_level_region',
  'multi_city_vicinity',
  'province_state',
]

export const TOUR_MINIMUM_VALUED_AT_PERCENTAGE_TO_SHOW = 5

export const DEFAULT_TOUR_V2_OFFER_MAX_CHILD_AGE = 17

export const DEFAULT_TOUR_V2_PRIVATE_MAX_CHILD_AGE = 15

export const VendorsThatRequireTravellerDetails = new Set(['00128000018BZkIAAW', '0010I00001ba7JdQAI', '0010I00001f6bHvQAI', '0010I000028DrsxQAC', '0012y00000LHHZoAAP'])

export const TOUR_V2_MALE_TRAVELLER_TITLES = ['Mr', 'Master']

export const TOUR_V2_FEMALE_TRAVELLER_TITLES = ['Ms', 'Mrs', 'Miss']

export const TOUR_V2_LE_SOURCE = 'salesforce'

export const tourDurationOptions: Array<DurationOption> = [
  {
    title: 'Any duration',
    caption: 'I’ll decide later',
    id: TOUR_FLEXIBLE_DURATION_RANGE.ANY_DURATION,
  },
  {
    title: 'Short tour',
    caption: '1 to 7 days',
    id: TOUR_FLEXIBLE_DURATION_RANGE.SHORT_STAY,
  },
  {
    title: 'Medium tour',
    caption: '8 to 14 days',
    id: TOUR_FLEXIBLE_DURATION_RANGE.MEDIUM_STAY,
  },
  {
    title: 'Long tour',
    caption: '15+ days',
    id: TOUR_FLEXIBLE_DURATION_RANGE.LONG_STAY,
  },
]

export const TOUR_V2_PACKAGE_OPTION_BASE = 'Base'
export const TOUR_V2_PACKAGE_OPTION_EXTENDED = 'Extended'
export const TOUR_V2_PACKAGE_OPTION_EXTENDED_ALTERNATIVE = 'Extended Alternative'

export const tourOptionPackages = [
  TOUR_V2_PACKAGE_OPTION_BASE,
  TOUR_V2_PACKAGE_OPTION_EXTENDED,
  TOUR_V2_PACKAGE_OPTION_EXTENDED_ALTERNATIVE,
]

export const tourOptionPackagesEvent = {
  [TOUR_V2_PACKAGE_OPTION_BASE]: OfferContextMap.VIEW_TOUR_OPTIONS_PRESSED,
  [TOUR_V2_PACKAGE_OPTION_EXTENDED]: OfferContextMap.VIEW_TOUR_OPTIONS_EXTENDED_PRESSED,
  [TOUR_V2_PACKAGE_OPTION_EXTENDED_ALTERNATIVE]: OfferContextMap.VIEW_TOUR_OPTIONS_EXTENDED_ALT_PRESSED,
}

export enum PACKAGE_UPGRADE_VALUES {
  REGULAR = 'regular',
  VIP = 'vip',
  PLATINUM = 'platinum',
}

export const tourOptionPackageUpgrades = [PACKAGE_UPGRADE_VALUES.REGULAR, PACKAGE_UPGRADE_VALUES.VIP, PACKAGE_UPGRADE_VALUES.PLATINUM]

export enum PACKAGE_UPGRADE_DISPLAY_TEXT {
  REGULAR = 'Classic',
  VIP = 'Superior',
  PLATINUM = 'Deluxe',
}

export enum PACKAGE_UPGRADE_DISPLAY_ECO_BUSINESS_TEXT {
  REGULAR = 'Economy',
  VIP = 'Business',
  PLATINUM = 'First Class',
}
export const getOfferPackageUpgradeDisplayTextMap = (offerId : string) => {
  switch (offerId?.replace('tour-', '')) {
    case 'bbf97caa-d857-4517-b768-465f5ceac8db': {
      return PACKAGE_UPGRADE_DISPLAY_ECO_BUSINESS_TEXT_MAP
    }
    default:
      return PACKAGE_UPGRADE_DISPLAY_TEXT_MAP
  }
}

const PACKAGE_UPGRADE_DISPLAY_TEXT_MAP = new Map([
  [PACKAGE_UPGRADE_VALUES.REGULAR, PACKAGE_UPGRADE_DISPLAY_TEXT.REGULAR],
  [PACKAGE_UPGRADE_VALUES.VIP, PACKAGE_UPGRADE_DISPLAY_TEXT.VIP],
  [PACKAGE_UPGRADE_VALUES.PLATINUM, PACKAGE_UPGRADE_DISPLAY_TEXT.PLATINUM],
])
const PACKAGE_UPGRADE_DISPLAY_ECO_BUSINESS_TEXT_MAP = new Map([
  [PACKAGE_UPGRADE_VALUES.REGULAR, PACKAGE_UPGRADE_DISPLAY_ECO_BUSINESS_TEXT.REGULAR],
  [PACKAGE_UPGRADE_VALUES.VIP, PACKAGE_UPGRADE_DISPLAY_ECO_BUSINESS_TEXT.VIP],
  [PACKAGE_UPGRADE_VALUES.PLATINUM, PACKAGE_UPGRADE_DISPLAY_ECO_BUSINESS_TEXT.PLATINUM],
])

export enum TOURS_GROUP_TYPE {
  isPrivateRequest = 'isPrivateRequest',
  isSmallGroup = 'isSmallGroup',
  isLargeGroup = 'isLargeGroup',
  isFamilyFriendly = 'isFamilyFriendly',
}

export const TOURS_GROUP_TYPES_LIST = Object.values(TOURS_GROUP_TYPE)

export enum TOUR_OFFER_TAG {
  onSale = 'onSale'
}

export const TOUR_OFFER_TAG_LIST = Object.values(TOUR_OFFER_TAG)

export const TOUR_MAX_ROOM_LIMIT = 5

export const TOUR_OPTIONAL_EXPERIENCE_BOOKING_DAYS_BEFORE_DEPARTURE = 60

export const TOUR_OPTIONAL_EXPERIENCE_RESERVATION_STATUS_CANCELLED = 'cancelled'
